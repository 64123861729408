import { Layout } from "@components";

import TutorialIndex from "@content/tutorial/a-to-z/index.fr";

export default function Tutorial() {
  return (
    <Layout title="Concevoir une étude de A à Z">
      <TutorialIndex currenttuto="AtoZ" />
    </Layout>
  );
}
